<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="编辑调查问卷" class="margin-bottom margin-top-xs"></el-page-header>
		<el-divider content-position="left">基本信息</el-divider>
		<div>
			<div class="form_box">
				<el-form ref="formOne" :model="formOne" label-width="100px" class="flex flex-wrap" v-loading="loading">
					<el-form-item label="姓名" prop="name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="formOne.name" placeholder="请填写顾客姓名"></el-input>
						</div>
					</el-form-item>

					<!-- 	<el-form-item label="填写日期" prop="birthday">
						<div class="form-item-box">
							<el-date-picker v-model="formOne.birthday" type="date" placeholder="请填写日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item> -->
					<el-form-item label="填写日期" prop="created_at" :rules="rules.required">
						<div class="form-item-box">

							<el-date-picker style="width: 100%;" v-model="formOne.created_at" type="datetime"
								format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="选择日期时间">
							</el-date-picker>
						</div>
					</el-form-item>

					<el-form-item label="联系电话" prop="phone" :rules="[rules.required,rules.phone]">
						<div class="form-item-box">
							<el-input v-model="formOne.phone" placeholder="请填写顾客联系电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="服务技师" prop="operater_name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="formOne.operater_name" placeholder="请填写服务技师"></el-input>
						</div>
					</el-form-item>



				</el-form>
			</div>
			<el-divider content-position="left">详细资料</el-divider>
			<div class="form_box">
				<el-form ref="formTwo" :model="formTwo" label-width="400px" label-position="top"
					style="margin-left: 50px;">
					<el-form-item label="1、您是预约几次才成功？" prop="one" :rules="rules.required">
						<el-radio-group v-model="formTwo.one">
							<el-radio label="A">1次</el-radio>
							<el-radio label="B">2次</el-radio>
							<el-radio label="C">3次</el-radio>
							<el-radio label="D">3次以上</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="2、服务过程所花时间？" prop="two" :rules="rules.required">
						<el-radio-group v-model="formTwo.two">
							<el-radio label="A">30分钟</el-radio>
							<el-radio label="B">45分钟</el-radio>
							<el-radio label="C">60分钟</el-radio>
							<el-radio label="D">60分钟以上</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="3、老师是否有礼貌和笑容？" prop="three" :rules="rules.required">
						<el-radio-group v-model="formTwo.three">
							<el-radio label="A">足够</el-radio>
							<el-radio label="B">普通</el-radio>
							<el-radio label="C">部分欠缺</el-radio>
							<el-radio label="D">完成欠缺</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="4、是否满意老师的专业技术和手法？" prop="four" :rules="rules.required">
						<el-radio-group v-model="formTwo.four">
							<el-radio label="A">非常满意</el-radio>
							<el-radio label="B">普通</el-radio>
							<el-radio label="C">部分满意</el-radio>
							<el-radio label="D">差劣</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="5、老师有否根据您的需要而推荐其它适合的项目或产品给您？" prop="five" :rules="rules.required">
						<el-radio-group v-model="formTwo.five">
							<el-radio label="A">建议中肯，非常适合</el-radio>
							<el-radio label="B">建议普通，部分适合</el-radio>
							<el-radio label="C" style="margin-top: 10px;">建议无用，不适合</el-radio>
							<el-radio label="D" style="margin-top: 10px;">没有建议</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="6、是否满意店内的环境卫生？" prop="six" :rules="rules.required">
						<el-radio-group v-model="formTwo.six">
							<el-radio label="A">非常满意</el-radio>
							<el-radio label="B" name="sleep_quality">普通</el-radio>
							<el-radio label="C" name="sleep_quality">部分满意</el-radio>
							<el-radio label="D" name="sleep_quality">差劣</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="7、店内仪器设施和用品是否干净、整洁？" prop="seven" :rules="rules.required">
						<el-radio-group v-model="formTwo.seven">
							<el-radio label="A">非常干净</el-radio>
							<el-radio label="B">干净</el-radio>
							<el-radio label="C">普通</el-radio>
							<el-radio label="D">差劣</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="8、是否满意老师的服务态度？" prop="eight" :rules="rules.required">
						<el-radio-group v-model="formTwo.eight">
							<el-radio label="A">非常满意</el-radio>
							<el-radio label="B">普通</el-radio>
							<el-radio label="C">部分满意</el-radio>
							<el-radio label="D">差劣</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="9、您对门店或首发老师其它意见和建议">
						<el-input v-model="formTwo.nine" type="textarea" :autosize="{ minRows: 4}"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>

				</el-form>
			</div>

		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";

	export default {
		data() {
			return {
				loading: false,
				rules,
				formOne: {
					id: this.$route.params.id,
					name: "",
					phone: '',
					operater_name: '',
					created_at: '',
				},
				formTwo: {
					one: "",
					two: '',
					three: '',
					four: "",
					five: '',
					six: '',
					seven: '',
					eight: "",
					nine: ""


				},
			};
		},
		computed: {

		},
		mounted() {
			if (this.formOne.id) {
				this.getInfo()
			}
		},
		methods: {
			//获取客户意详情
			getInfo() {
				this.$api.tablewx.customerQuestionnaireInfo({
					id: this.formOne.id
				}).then(res => {
					this.formOne = res.data
					this.formTwo.one = res.data.item_list[0].choice
					this.formTwo.two = res.data.item_list[1].choice
					this.formTwo.three = res.data.item_list[2].choice
					this.formTwo.four = res.data.item_list[3].choice
					this.formTwo.five = res.data.item_list[4].choice
					this.formTwo.six = res.data.item_list[5].choice
					this.formTwo.seven = res.data.item_list[6].choice
					this.formTwo.eight = res.data.item_list[7].choice
					this.formTwo.nine = res.data.item_list[8].value



				})
			},
			onSubmit() {
				const formOne = new Promise((resolve) => {
					this.$refs.formOne.validate(valid => {
						if (valid) resolve()
					})
				})
				const formTwo = new Promise((resolve) => {
					this.$refs.formTwo.validate(valid => {
						if (valid) resolve()
					})
				})

				Promise.all([formOne, formTwo]).then(() => {
					const loading = this.$loading();
					this.$api.tablewx.customerQuestionnaireAdd({
						id: this.formOne.id,
						name: this.formOne.name,
						phone: this.formOne.phone,
						operater_name: this.formOne.operater_name,
						created_at: this.formOne.created_at,
						item_option: {
							"1": this.formTwo.one,
							"2": this.formTwo.two,
							"3": this.formTwo.three,
							"4": this.formTwo.four,
							"5": this.formTwo.five,
							"6": this.formTwo.six,
							"7": this.formTwo.seven,
							"8": this.formTwo.eight,
							"9": this.formTwo.nine,

						}
					}).then(res => {
						loading.close();
						if (res.code == 200) {
							// 保存成功
							this.$message.success(`保存成功`);
							this.$router.back();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						loading.close();
						this.$alert(err);
					});

				})


			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
